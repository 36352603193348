<template>
<v-container>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Reservierungen</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="searchReservation"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          clearable
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn outlined @click="getReservations" icon color="primary"><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
          :items="reservations"
          :headers="reservationHeaders"
          :loading="reservationLoading"
          :search="searchReservation"
      >
        <template v-slot:item.id="{item}">
          <v-btn text :to="'/reservations/' + item.id">{{item.id}}</v-btn>
        </template>
        <template v-slot:item.status="{item}">
          <v-chip>{{item.status}}</v-chip>
        </template>
        <template v-slot:item.listingName="{item}">
          <div @click="navigate('/listings/' + item.listingMapId)" style="cursor: pointer;">{{item.listingName}}</div>
        </template>
        <template v-slot:item.guestPicture="{item}">
          <v-avatar size="24" color="secondary">
            <img :src="item.guestPicture" v-if="item.guestPicture !== null">
            <span class="white--text" v-else>{{getInitials(item)}}</span>
          </v-avatar>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  <v-card class="mt-5">
    <v-toolbar flat>
      <v-toolbar-title>Listings</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="searchListing"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          clearable
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn outlined @click="getListings" icon><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
          :items="listings"
          :headers="listingHeaders"
          :loading="listingLoading"
          :search="searchListing"
      >
        <template v-slot:item.id="{item}">
          <v-btn text :to="'/listings/' + item.id">{{item.id}}</v-btn>
        </template>
        <template v-slot:item.thumbnailUrl="{item}">
          <v-avatar size="24" color="secondary">
            <img :src="item.thumbnailUrl" v-if="item.thumbnailUrl !== null">
            <span class="white--text" v-else>{{item.name.substr(0,1)}}</span>
          </v-avatar>
        </template>
        <template v-slot:item.name="{item}">
          <div @click="navigate('/listings/' + item.id)">{{item.name}}</div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
  <v-card class="mt-5">
    <v-toolbar flat>
      <v-toolbar-title>Zahlungen</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined @click="getPayments" icon><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
          :items="payments"
          :headers="paymentHeaders"
          :loading="paymentLoading"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
  <v-card class="mt-5">
    <v-toolbar flat>
      <v-toolbar-title>Amenities</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outlined @click="getAmenities" icon><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table
          :items="amenities"
          :headers="amenitiesHeaders"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "@/main";
export default {
  name: "Reservations",
  data: () => ({
    reservationHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Status', value: 'status' },
      { text: 'Listing', value: 'listingName' },
      { text: 'Bild', value: 'guestPicture' },
      { text: 'Vorname', value: 'guestFirstName' },
      { text: 'Nachname', value: 'guestLastName' },
      { text: 'Channel', value: 'channelName' },
      { text: 'Ankunft', value: 'arrivalDate' },
      { text: 'Abreise', value: 'departureDate' },
      { text: 'Nächte', value: 'nights' },
    ],
    reservationLoading: false,
    searchReservation: "",
    listingHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Thumbnail', value: 'thumbnailUrl' },
      { text: 'Name', value: 'name' },
      { text: 'Adresse', value: 'address' },
      { text: 'Straße', value: 'street' },
      { text: 'Stadt', value: 'city' },
      { text: 'Kapazität', value: 'personCapacity' },
    ],
    listingLoading: false,
    searchListing: "",
    paymentHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Listing', value: 'listingMapId' },
      { text: 'Reservierung', value: 'reservationId' },
      { text: 'Typ', value: 'type' },
      { text: 'Beschreibung', value: 'description' },
      { text: 'Währung', value: 'currency' },
      { text: 'Betrag', value: 'amount' },
      { text: 'Zahlungsmethode', value: 'paymentMethod' },
    ],
    paymentLoading: false,
    amenitiesHeaders: [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
    ],
    reservations: [],
    listings: [],
    payments: [],
    amenities: [],
  }),
  methods: {
    getReservations() {
      this.reservationLoading = true;
      axios.get( process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/reservations").then((res) => {
        console.log(res.data)
        this.reservations = res.data.result
        this.reservationLoading = false;
      })
    },
    getListings() {
      this.listingLoading = true
      axios.get( process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/listings").then((res) => {
        console.log(res.data)
        this.listings = res.data.result
        this.listingLoading = false
      })
    },
    getPayments() {
      this.paymentLoading = true
      axios.get( process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/payments").then((res) => {
        console.log(res.data)
        this.payments = res.data.result
        this.paymentLoading = false
      })
    },
    getAmenities() {
      axios.get( process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/amenities").then((res) => {
        console.log(res.data)
        this.amenities = res.data.result
      })
    },
    getInitials(reservation) {
      let initials = "XX";
      if(reservation.guestFirstName) {
        initials = reservation.guestFirstName.substr(0,1).toUpperCase();
      } else {
        initials = "X";
      }
      if(reservation.guestLastName) {
        initials = initials + reservation.guestLastName.substr(0,1).toUpperCase();
      } else {
        initials = initials + "X";
      }
      return initials;
    },
    navigate(route) {
      this.$router.push(route)
    },
  },
  created() {
    this.getReservations();
    this.getListings();
    this.getPayments();
    this.getAmenities();
  }
}
</script>

<style scoped>

</style>