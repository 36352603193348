<template>
<v-container>
  <v-card>
    <v-progress-linear
        indeterminate
        v-if="loading"
    ></v-progress-linear>
    <v-card-title><v-btn to="/reservations" icon><v-icon>mdi-arrow-left</v-icon></v-btn>&nbsp;Reservierung {{id}}</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="getListingCustomFields" :disabled="loading">Get Listing CustomFields</v-btn>
      <v-btn outlined @click="generateCheckinForm" :disabled="loading">Gen CheckinForm</v-btn>
      <v-btn outlined @click="getCustomFields" :disabled="loading">Custom Fields</v-btn>
      <v-btn outlined color="primary" @click="updateReservation" :disabled="loading">Update</v-btn>
    </v-card-actions>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="getConversations" :disabled="loading">Conversations</v-btn>
      <v-btn outlined color="primary" @click="sendManualCheckinMessage" :disabled="loading">send checkin message</v-btn>
    </v-card-actions>
    <v-card-text>
      <v-textarea outlined label="Message" v-model="guestMessage"></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="sendGuestMessage" :disabled="loading">Send</v-btn>
    </v-card-actions>
    <v-card-text v-if="reservation !== null">
      <v-row>
        <v-col cols="4">
          <v-avatar size="42" color="secondary">
            <img :src="reservation.guestPicture" v-if="reservation.guestPicture !== null">
            <span class="white--text" v-else>{{getInitials(reservation)}}</span>
          </v-avatar>
        </v-col>
        <v-col cols=8>{{reservation.guestName}}</v-col>
        <v-col cols="4">Status <v-chip>{{reservation.status}}</v-chip></v-col>
        <v-col cols="4">
          <v-text-field readonly label="Ankunft" v-model="reservation.arrivalDate" prepend-icon="mdi-calendar" outlined></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field readonly label="Abreise" v-model="reservation.departureDate" prepend-icon="mdi-calendar" outlined></v-text-field>
        </v-col>
      </v-row>
      <v-row v-for="cf in reservation.customFieldValues" :key="cf.id">
        <v-col>{{cf.customField.name}}</v-col>
        <v-col v-if="cf.customField.name === 'reservation_jotform_url'"><a :href="cf.value">Zum Formular</a></v-col>
        <v-col v-else>{{cf.value}}</v-col>
      </v-row>
      <pre>{{reservation}}</pre>
      <pre>{{customFields}}</pre>
      <pre>{{listingCustomFields}}</pre>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import axios from "@/main";
export default {
  name: "ReservationDetails",
  data: () => ({
    id: "",
    loading: false,
    reservation: null,
    customFields: [],
    listingCustomFields: {},
    guestMessage: "",
  }),
  methods: {
    getReservation(id) {
      this.loading = true
      axios.get(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/reservations/" + id).then((res) => {
        console.log(res.data)
        this.reservation = res.data.result
        this.loading = false
      })
    },
    getInitials(reservation) {
      let initials = "XX";
      if(reservation.guestFirstName) {
        initials = reservation.guestFirstName.substr(0,1).toUpperCase();
      } else {
        initials = "X";
      }
      if(reservation.guestLastName) {
        initials = initials + reservation.guestLastName.substr(0,1).toUpperCase();
      } else {
        initials = initials + "X";
      }
      return initials;
    },
    getCustomFields() {
      axios.get(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/reservations/customFields").then((res) => {
        this.customFields = res.data;
      })
    },
    updateReservation() {
      console.log(this.reservation)
      let updatedReservation = Object.assign({}, this.reservation);
      let customFields = Object.assign([], this.reservation.customFieldValues);
      updatedReservation.customFieldValues = customFields;
      updatedReservation.customFieldValues = [];
      updatedReservation.customFieldValues.push({
        "customFieldId" : 42732,
        "value" : "",
      }); //42732
      /* updatedReservation.customFieldValues.push({
        "customFieldId" : 49228,
        "value" : "https://halfbaked.io/123",
      }); */
      axios.put(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/reservations/" + this.id, updatedReservation).then((res)=>{
        console.log("All ok")
        console.log(res.data)
        this.getReservation(this.id)
      })
    },
    generateCheckinForm() {
      console.log(this.reservation)
      let updatedReservation = Object.assign({}, this.reservation);
      axios.put(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/reservations/" + this.id + "/generateForm", updatedReservation).then((res)=>{
        console.log("All ok")
        console.log(res.data)
        this.getReservation(this.id);
      })
    },
    getListingCustomFields() {
      axios.get(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/listings/customFields").then((res) => {
        this.listingCustomFields = res.data
      })
    },
    getConversations() {
      axios.get(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/reservations/" + this.id + "/conversations").then((res) => {
        console.log(res.data)
      })
    },
    sendGuestMessage() {
      axios.post(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/reservations/" + this.id + "/conversations",{
        body: this.guestMessage,
      })
          .then((res) => {
            console.log(res.data)
            this.getConversations();
          })
    },
    sendManualCheckinMessage() {
      let message = "Dear " + this.reservation.guestFirstName + "\n\n" +
          "Thank you for booking our apartment from " + this.reservation.arrivalDate + " until " + this.reservation.departureDate + " for " + this.reservation.numberOfGuests + " guest(s).\n" +
          "\n" +
          "Next step: Austrian law requires you to fill out a guest registration form prior to your check-in. Your personal data is secured and will NOT be shared for advertisement etc.\n" +
          "Only after receiving the form, we are allowed to give access to the apartment!\n" +
          "\n" +
          "LINK TO THE FORM:\n" +
          this.getJotformUrl()
      this.guestMessage = message;
      /* axios.post(process.env.VUE_APP_HOST_APART_API_BASE_URL + "/api/v1/reservations/" + this.id + "/conversations",{
        body: message,
      })
          .then((res) => {
            console.log(res.data)
            this.getConversations();
          }) */
    },
    getJotformUrl() {
      let jotformUrlCustomField = this.reservation.customFieldValues.filter(e => e.customFieldId == 49228);
      return jotformUrlCustomField[0].value;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getReservation(this.id);
  },
}
</script>

<style scoped>

</style>